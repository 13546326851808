import { useCallback, useEffect, useState } from 'react'

export const useHash = (): [string, (newHash: string) => void] => {
  const [hash, setHash] = useState(() => window.location.hash)

  const hashChangeHandler = useCallback(() => {
    setHash(window.location.hash)
  }, [])

  useEffect(() => {
    window.addEventListener('hashchange', hashChangeHandler)
    return () => {
      window.removeEventListener('hashchange', hashChangeHandler)
    }
  }, [])

  const updateHash = useCallback(
    (newHash: string) => {
      if (newHash === hash) return

      if (newHash !== '') {
        location.hash = newHash
      } else {
        history.replaceState(
          null,
          '',
          location.pathname + location.hash + location.search
        )
        setHash('')
      }
    },
    [hash]
  )

  return [hash, updateHash]
}
